@mixin btn($btn-bg, $btn-text, $btn-hover) {
  padding: 0.75rem;
  color: $btn-text;
  background-color: $btn-bg;
  border: none;
  border-radius: 10px;
  font-size: 0.75rem;
  box-shadow: 0px 12px 15px 2px rgba(0, 0, 0, 0.1);
  transition: transform 0.1s ease-in-out;
  @include breakpoint(x-small) {
    padding: 15px;
    font-size: 14px;
  }
  @include breakpoint(small) {
    padding: 15px 30px;
    font-size: 14px;
  }
  &:active,
  &:hover {
    color: $btn-text !important;
    background-color: $btn-bg !important;
    border-color: $btn-bg !important;
    @include breakpoint(small) {
      transform: translateY(4px);
      background-color: $btn-hover !important;
    }
  }
  &:focus {
    background: $btn-bg;
    box-shadow: 0px 8px 3px 2px rgba(0, 0, 0, 0.1) !important;
    outline: none !important;
  }
  span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    padding-right: 90px;
    @include breakpoint(small) {
      padding-right: 0px;
    }
  }
  span:after {
    content: url("../images/arrow.svg");
    position: absolute;
    opacity: 1;
    top: 2px;
    right: 10px;
    transition: 0.5s;
    @include breakpoint(small) {
      opacity: 0;
      right: -20px;
    }
  }
  &:hover span {
    padding-right: 90px;
  }

  &:hover span:after {
    opacity: 1;
    right: 0;
  }
}

@mixin btn-attach($btn-bg, $btn-text) {
  padding: 12px 25px;
  color: $btn-text;
  background-color: $btn-bg;
  border: none;
  border-radius: 0 10px 10px 0;
  font-size: 12px;
  @include breakpoint(small) {
    padding: 15px 35px;
  }
  &:active,
  &:hover {
    color: $btn-text !important;
    background-color: $btn-bg !important;
    border-color: $btn-bg !important;
  }
  &:focus {
    background: $btn-bg;
    box-shadow: none !important;
    outline: none !important;
  }
}

@mixin btn-outline($btn-bg, $btn-text) {
  padding: 0.75rem;
  color: $btn-text;
  background-color: transparent;
  border-color: $btn-text;
  border-radius: 10px;
  font-size: 0.75rem;
  transition: transform 0.1s ease-in-out;
  @include breakpoint(x-small) {
    padding: 15px;
    font-size: 14px;
  }
  @include breakpoint(small) {
    padding: 15px 30px;
    font-size: 14px;
  }
  &:focus {
    background: transparent;
    box-shadow: none !important;
    outline: none !important;
    color: $btn-text !important;
    border-color: $btn-text !important;
  }
  &:active,
  &:hover {
    color: $btn-text !important;
    background-color: transparent !important;
    border-color: $btn-text !important;
    @include breakpoint(small) {
      color: $btn-bg !important;
      background-color: $btn-text !important;
      border-color: $btn-text !important;
    }
  }
  &:hover {
    @include breakpoint(small) {
      transform: translateY(4px);
    }
  }
}

@mixin btn-icon($btn-bg, $btn-text, $btn-border) {
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  color: $btn-text;
  background-color: $btn-bg;
  border: none;
  font-size: 14px;
  &:active,
  &:hover {
    color: $btn-text !important;
    background-color: $btn-bg !important;
    border-color: $btn-border !important;
  }
  &:focus {
    background: $btn-bg;
    box-shadow: none !important;
    outline: none !important;
    color: $btn-text !important;
    border-color: $btn-border !important;
  }
}

@mixin breakpoint($point) {
  $breakpoint-extra-small: "(min-width: 376px)";
  $breakpoint-small: "(min-width: 769px)";
  $breakpoint-large: "(min-width: 1025px)";
  $breakpoint-extra-large: "(min-width: 1441px)";

  @if $point == small {
    @media screen and #{$breakpoint-small} {
      @content;
    }
  } @else if $point == large {
    @media screen and #{$breakpoint-large} {
      @content;
    }
  } @else if $point == x-small {
    @media screen and #{$breakpoint-extra-small} {
      @content;
    }
  } @else if $point == x-large {
    @media screen and #{$breakpoint-extra-large} {
      @content;
    }
  }
}
