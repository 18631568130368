$primary-color: #06D6A0;
$bg-color: #0D0A3A;
$bg-color-2: #F6F6FF;
$bg-color-3: linear-gradient(135deg, #5D5B7B, #0D0A3A);
$bg-color-4: #F2F1F9;
$bg-color-5: rgba(255, 255, 255, 0.3);
$bg-color-6: rgba(13, 10, 58, 0.5);
$bg-color-7: #E6E6F6;
$bg-color-8: #D1FFF4;
$bg-color-9: #F5F5FC;
$text-shadow-1: #191644;
$text-shadow-2: #0D0A3A;
$text-shadow-3: #F3F3F5;
$white: #fff;
$black: #000;
$text-color: #080626;
$text-color-2: #1B1B1B;
$text-color-3: rgba(27, 24, 69, 0.72);
$text-color-4: rgba(13, 10, 58, 0.8);
$hover-color: #00BC8B;
$border-color: #71F3D4;
$border-color-2: #7F9C7D;
$border-color-3: #BFBFF6;
$progress-bar-color: rgba(13, 10, 58, 0.19);
$tooltip-color: #040404;