@font-face {
    font-family: '$font-extra-bold';
    src: url('../fonts/HKGrotesk-ExtraBold.otf');
    font-display: swap;
}

@font-face {
    font-family: '$font-medium';
    src: url('../fonts/HKGrotesk-Medium.otf');
    font-display: swap;
}

@font-face {
    font-family: '$font-museo';
    src: url('../fonts/Museo\ 500.otf');
    font-display: swap;
}

@font-face {
    font-family: '$font-bold';
    src: url('../fonts/HKGrotesk-Bold.otf');
    font-display: swap;
}

@font-face {
    font-family: '$font-semi-bold';
    src: url('../fonts/HKGrotesk-SemiBold.otf');
    font-display: swap;
}

@font-face {
    font-family: '$font-light';
    src: url('../fonts/HKGrotesk-Light.otf');
    font-display: swap;
}

@font-face {
    font-family: '$font-regular';
    src: url('../fonts/HKGrotesk-Regular.otf');
    font-display: swap;
}

@font-face {
    font-family: '$font-museo-regular';
    src: url('../fonts/Museo300-Regular.woff');
    font-display: swap;
}

@font-face {
    font-family: '$font-museo-700';
    src: url('../fonts/Museo700-Regular.woff');
    font-display: swap;
}