* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

/* .container {
  width: calc(100% - 22px);
  @media screen and (min-width: 768px) {
    width: 724px;
  }
  @media screen and (min-width: 991px) {
    width: 925px;
  }
  @media screen and (min-width: 1360px) {
    width: 724px;
  }
} */